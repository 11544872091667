import React from "react";
import styled from "styled-components";

import media from "styled-media-query";

import Img from "gatsby-image";

const Base = styled.a`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-width: 300px;
  color: inherit;
  text-decoration: none;

  & img {
    transition: 0.2s ease-in-out;
    filter: grayscale(1) !important;
  }

  &:hover {
    text-decoration: underline;

    & img {
      filter: grayscale(0) !important;
    }
  }

  &:not(:last-child) {
    margin-right: 64px;

    ${media.lessThan("690px")`
      margin-right: 0px;
      margin-bottom: 32px;
    `}
  }
`;

const Thumb = styled(Img)`
  width: 100%;
  height: 180px;
  object-fit: cover;

  ${media.lessThan("small")`
    width: 80%;
    height: 130px;
  `}
`;

const Title = styled.div`
  font-weight: 400;
  color: black;
  font-size: 22px;
  margin-top: 16px;
  line-height: 1.2;
  margin-bottom: 2px;

  ${media.lessThan("small")`
    margin-top: 12px;
    font-size: 18px;
  `}
`;

const Subtitle = styled.div`
  font-weight: 300;
  color: #3c3c3c;
  font-size: 16px;

  ${media.lessThan("small")`
    font-size: 14px;
  `}
`;

export default function MediaItem({ imgUrl, title, source, link }) {
  return (
    <Base rel="noopener" target="_blank" href={link}>
      <Thumb alt="" fluid={imgUrl} />
      <Title>{title}</Title>
      <Subtitle style={{ textDecoration: "none" }}>{source}</Subtitle>
    </Base>
  );
}
