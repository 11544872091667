import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { getMonth, getDate, getYear } from "date-fns";

const months = [
  "januari",
  "februari",
  "maart",
  "april",
  "mei",
  "juni",
  "juli",
  "augustus",
  "september",
  "oktober",
  "november",
  "december"
];

const ItemBase = styled.div`
  font-weight: 300;
  display: flex;
  margin-bottom: 8px;

  ${media.lessThan("small")`
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  `}
`;

const Location = styled.div`
  color: black;
  font-weight: 400;
  flex: 1;
`;
const DateStr = styled.div`
  flex: 1;

  ${media.lessThan("small")`
    font-size: 0.9rem;
  `}
`;

const Link = styled.a`
  color: black;

  ${media.lessThan("small")`
    font-size: 0.9rem;
  `}
`;

function AgendaItem({ location, city, date, link }) {
  return (
    <ItemBase>
      <Location>
        {location}, {city}
      </Location>
      <DateStr>{date}</DateStr>
      <Link target="_blank" rel="noopener noreferrer" href={link}>
        Bestel kaarten
      </Link>
    </ItemBase>
  );
}

const Base = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

export default function Agenda() {
  const [items, setItems] = React.useState();

  React.useEffect(() => {
    fetch("/api/agenda")
      .then(res => res.json())
      .then(items => setItems(items));
  }, []);

  return (
    <Base>
      {items ? (
        items.length === 0 ? (
          <p style={{ textAlign: "center" }}>Binnenkort meer...</p>
        ) : (
          <>
            {items.map(item => {
              const date = new Date(item.date);

              return (
                <AgendaItem
                  key={item.id}
                  location={item.location}
                  city={item.city}
                  date={`${getDate(date)} ${months[getMonth(date)]}, ${getYear(
                    date
                  )}`}
                  link={item.link}
                />
              );
            })}
          </>
        )
      ) : null}
    </Base>
  );
}
